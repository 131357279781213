import {SVGProps} from 'react'

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.275 17.6538L6.34625 16.725L11.0713 12L6.34625 7.275L7.275 6.34625L12 11.0713L16.725 6.34625L17.6538 7.275L12.9288 12L17.6538 16.725L16.725 17.6538L12 12.9288L7.275 17.6538Z"
      fill="currentColor"
    />
  </svg>
)

export default CloseIcon
