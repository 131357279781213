import {SVGProps} from 'react'

const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.09998 17.0345V15.7348H19.9V17.0345H4.09998ZM4.09998 12.65V11.35H19.9V12.65H4.09998ZM4.09998 8.26526V6.96552H19.9V8.26526H4.09998Z"
      fill="currentColor"
    />
  </svg>
)

export default MenuIcon
