import LogoIcon from '@/icons/LogoIcon'
import Link from 'next/link'

const Logotype = ({...rest}) => {
  return (
    <Link href="/" aria-label="Homepage link" {...rest}>
      <LogoIcon />
    </Link>
  )
}

export default Logotype
