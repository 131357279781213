'use client'
import {useRouter} from 'next/navigation'
import {COOKIE_CODE} from './GoogleTagManager'

const ManageCookies = () => {
  const router = useRouter()

  const onCookiesClear = () => {
    if (typeof window !== 'undefined') localStorage.removeItem(COOKIE_CODE)
    router.refresh()
  }
  return (
    <p onClick={onCookiesClear} className="underline text-body-xs hover:cursor-pointer">
      Manage Settings
    </p>
  )
}

export default ManageCookies
