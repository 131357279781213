'use client'
import Link from 'next/link'
import Button from './Button'
import {useEffect, useState} from 'react'
import {COOKIE_CODE} from './GoogleTagManager'

type Props = {
  onConsent: (hasConsented: boolean) => void
}
const CookiesConsentBanner = ({onConsent}: Props) => {
  const [shouldShowConsentBanner, setShouldShowConsentBanner] = useState(false)

  // Not passing dependencies is intentional. After several clicks on manage settings this useEffect is not being triggered with [].
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setShouldShowConsentBanner(typeof window !== 'undefined' && localStorage.getItem(COOKIE_CODE) === null))

  if (!shouldShowConsentBanner) return null

  const updateConsent = (hasConsented: boolean) => {
    localStorage.setItem(COOKIE_CODE, hasConsented.toString())
    setShouldShowConsentBanner(false)
    onConsent(hasConsented)
  }

  return (
    <div className="fixed bottom-0 left-0 z-50 flex w-full flex-col items-center justify-between gap-6 bg-black p-6 text-white transition-[bottom] duration-200 ease-out starting:-bottom-10 md:flex-row">
      <p className="text-body-md">
        Our website uses cookies to enhance your experience. By accepting, you agree to our{' '}
        <Link href="/privacy-policy" className="underline">
          Privacy policy
        </Link>
        .
      </p>
      <div className="mx-auto flex gap-x-6 md:mx-0">
        <Button label="Reject all" invertTextColor onClick={() => updateConsent(false)} />
        <Button label="Accept all" invertTextColor onClick={() => updateConsent(true)} />
      </div>
    </div>
  )
}

export default CookiesConsentBanner
