'use client'
import CloseIcon from '@/icons/CloseIcon'
import MenuIcon from '@/icons/MenuIcon'
import {useState, useCallback, useEffect} from 'react'
import Menu from './Menu'
import Logotype from './Logotype'
import Button from './Button'

const navItems = [
  {label: 'About', href: '/about'},
  {label: 'Services', href: '/services'},
  {label: 'News', href: '/news'}
]

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [shouldHideHeader, setShouldHideHeader] = useState(false)
  const [lastScrollPosition, setLastScrollPosition] = useState(0)

  const onScroll = useCallback(() => {
    setShouldHideHeader(window.scrollY > 60 && window.scrollY > lastScrollPosition)
    setLastScrollPosition(window.scrollY)
  }, [lastScrollPosition])

  useEffect(() => {
    window.addEventListener('scroll', onScroll, {passive: true})
    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  return (
    <header
      className={`fixed top-0 z-10 flex h-[72px] w-full items-center justify-between bg-white px-6 transition-transform duration-[1500ms] ${shouldHideHeader && !isMenuOpen ? '-translate-y-[96px] ease-in-out' : 'ease-out'} ${isMenuOpen ? 'touch-none bg-white lg:bg-transparent' : ''}`}
    >
      <Logotype className="z-10" onClick={() => setIsMenuOpen(false)} />
      <div className="relative z-10 flex cursor-pointer items-center lg:hidden" onClick={() => setIsMenuOpen(prev => !prev)}>
        <MenuIcon />
        <CloseIcon className={`absolute right-0 bg-white transition-opacity duration-300 ${isMenuOpen ? 'opacity-100' : 'opacity-0'}`} />
      </div>
      <nav className="hidden lg:flex lg:gap-x-[98px] lg:text-body-lg">
        {navItems.map(({label, href}) => (
          <Button
            key={href}
            href={href}
            label={label}
            aria-label={`Navigation link - ${label}`}
            variant="text"
            underlineByDefault={false}
            hoverColorChange={false}
          />
        ))}
      </nav>
      <div className="hidden lg:block lg:text-body-lg">
        <Button label="Contact us" variant="text" href="/contact" />
      </div>
      <Menu isMenuOpen={isMenuOpen} navItems={navItems} closeMenu={() => setIsMenuOpen(false)} />
    </header>
  )
}

export default Header
