'use client'
import Script from 'next/script'
import CookiesConsentBanner from './CookiesConsentBanner'
import {useState} from 'react'
export const COOKIE_CODE = 'cookie-consent'

const GoogleTagManager = () => {
  const consentExists = typeof window !== 'undefined' && localStorage.getItem(COOKIE_CODE) !== null
  const [hasConsented, setHasConsented] = useState(consentExists && localStorage?.getItem(COOKIE_CODE) === 'true')

  if (!consentExists) return <CookiesConsentBanner onConsent={(hasConsented: boolean) => setHasConsented(hasConsented)} />

  if (!hasConsented) return null
  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-7BV4E9V356" strategy="afterInteractive" />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || []; 
          function gtag(){dataLayer.push(arguments);} 
          gtag('js', new Date());           
          gtag('config', 'G-7BV4E9V356');
        `}
      </Script>
    </>
  )
}

export default GoogleTagManager
