import {SVGProps} from 'react'

const LogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="109" height="16" viewBox="0 0 109 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7_27)">
      <path d="M0 0V15H10V12.2668H3.23748V0H0Z" fill="currentColor" />
      <path d="M20 12H11V15H20V12Z" fill="currentColor" />
      <path d="M20 0H11V3H20V0Z" fill="currentColor" />
      <path d="M18 6H11V9H18V6Z" fill="currentColor" />
      <path
        d="M33.4748 4.26923C33.1296 3.36538 32.6423 2.59375 32.0082 1.95793C31.3765 1.32572 30.6334 0.840144 29.7666 0.503606C28.9036 0.169471 27.9523 0.00360577 26.9142 0H21V15H27.3966C28.3906 14.9952 29.2904 14.8113 30.0996 14.4459C30.9064 14.0769 31.6005 13.5577 32.1833 12.8798C32.766 12.2055 33.2116 11.4002 33.525 10.4639C33.8396 9.52524 33.9963 8.48558 34 7.34135C33.9963 6.19712 33.82 5.17308 33.476 4.26923H33.4748ZM30.3188 10.1731C30.0421 10.9291 29.6099 11.4976 29.0223 11.8738C28.4359 12.2548 27.6769 12.4471 26.7514 12.4471H24.1058V2.59856H26.4759C27.492 2.59375 28.3123 2.75361 28.9415 3.07692C29.5659 3.39663 30.0225 3.90865 30.3114 4.60457C30.5942 5.30529 30.7362 6.21635 30.7326 7.34135C30.7362 8.47115 30.5979 9.41707 30.3188 10.1743V10.1731Z"
        fill="currentColor"
      />
      <path
        d="M43.7826 3C43.1019 3 42.6477 3.36133 42.4177 4.08152L40.4129 11.4334L38.1536 3H35L38.9656 15H41.9926L46 3H43.7826Z"
        fill="currentColor"
      />
      <path
        d="M55.5628 4.55973C54.5744 3.52148 53.1837 3 51.3869 3C49.6818 3 48.3485 3.53564 47.3907 4.60338C46.4636 5.64163 46 7.08691 46 8.9357C46 10.7845 46.4795 12.3572 47.436 13.3966C48.3937 14.4644 49.7246 15 51.4333 15C52.84 15 54.0081 14.6673 54.9353 14.0019C55.8612 13.3671 56.4899 12.4421 56.8202 11.2305H54.5744C54.2136 11.2305 53.9372 11.3048 53.7427 11.4487C53.547 11.5927 53.4369 11.7059 53.4063 11.7921C53.3182 11.8817 53.2338 11.9596 53.1617 12.0339C53.0858 12.1024 53.0039 12.1554 52.9122 12.1849C52.5562 12.3879 52.1195 12.4882 51.6131 12.4882C50.8328 12.4882 50.2493 12.2545 49.8616 11.7921C49.4408 11.3591 49.2182 10.6995 49.1876 9.80051H56.9535C56.9853 9.74388 57 9.64242 57 9.4973V9.02065C57 7.11641 56.5217 5.62747 55.5628 4.55973ZM49.1864 7.85262C49.2169 7.10225 49.4396 6.5265 49.8603 6.11946C50.2787 5.71714 50.8181 5.51421 51.4737 5.51421C52.1942 5.51421 52.7458 5.71714 53.1372 6.11946C53.4981 6.49582 53.7035 7.07276 53.7659 7.85262H49.1864Z"
        fill="currentColor"
      />
      <path
        d="M59.5309 4.10314C58.5119 4.78089 58 6.12197 58 8.12037V15H61.016V7.5015C61.016 6.79371 61.2191 6.28179 61.6194 5.95734C62.0232 5.63529 62.4823 5.47306 62.9988 5.47306C63.5154 5.47306 63.9826 5.63529 64.3994 5.95734C64.8173 6.28179 65.0239 6.79371 65.0239 7.5015V13.5015C65.0239 13.9413 65.1601 14.2994 65.4325 14.5818C65.7084 14.8606 66.0571 15 66.4914 15H68V8.12037C68 6.06068 67.467 4.69437 66.4046 4.01662C65.3421 3.34008 64.1799 3 62.9143 3C61.6781 3.06129 60.5499 3.4302 59.5321 4.10314"
        fill="currentColor"
      />
      <path
        d="M70.0025 0V12.7625C70.0025 14.1114 70.5831 14.832 71.7517 14.9183C72.9178 15.0095 73.9998 15.0238 75 14.9639V12.6761H74.0456C73.4377 12.6761 73.1344 12.4243 73.1344 11.9244V5.41242H73.4513C73.8784 5.41242 74.2436 5.27333 74.5444 4.99636C74.8477 4.71339 74.9988 4.35847 74.9988 3.91843V3.30213H73.1332V1.49519C73.1332 1.08632 72.9822 0.731408 72.6789 0.440044C72.3768 0.147481 72.029 0 71.6353 0H70H70.0025Z"
        fill="currentColor"
      />
      <path
        d="M84.4913 3C84.0592 3 83.7093 3.14717 83.4334 3.4427C83.161 3.73703 83.0248 4.09168 83.0248 4.50422V10.5283C83.0248 11.234 82.8193 11.7527 82.4001 12.0772C81.9833 12.4017 81.5172 12.5645 80.9994 12.5645C80.4816 12.5645 80.0249 12.4017 79.6198 12.0772C79.2183 11.7527 79.0163 11.234 79.0163 10.5283V3H76V9.90712C76 11.9433 76.5119 13.2847 77.5311 13.9349C78.549 14.585 79.6773 14.9397 80.9137 15C82.1782 15 83.3406 14.6755 84.4032 14.0253C85.4658 13.3752 86 12.0036 86 9.90712V3H84.4901H84.4913Z"
        fill="currentColor"
      />
      <path
        d="M93.7636 3H93.5322C92.6908 3 91.8747 3.10324 91.0803 3.31585C90.2884 3.52724 89.5943 3.85539 89.0058 4.31012C88.3855 4.79066 87.8961 5.42851 87.5377 6.22737C87.1792 7.02253 87 8.00942 87 9.17943V15H90.2655V8.81934C90.2655 7.91602 90.4841 7.25358 90.9176 6.83327C91.3561 6.41417 92.026 6.20279 92.9234 6.20279H94V3H93.7648H93.7636Z"
        fill="currentColor"
      />
      <path
        d="M103.563 4.55973C102.574 3.52148 101.184 3 99.3869 3C97.6818 3 96.3497 3.53564 95.3907 4.60338C94.466 5.64163 94 7.08691 94 8.9357C94 10.7845 94.4795 12.3572 95.4384 13.3966C96.3962 14.4644 97.7246 15 99.4333 15C100.84 15 102.008 14.6673 102.934 14.0019C103.861 13.3671 104.49 12.4421 104.82 11.2305H102.574C102.214 11.2305 101.938 11.3048 101.744 11.4487C101.548 11.5927 101.437 11.7059 101.406 11.7921C101.318 11.8817 101.234 11.9596 101.159 12.0339C101.085 12.1024 101.004 12.1554 100.913 12.1849C100.556 12.3879 100.12 12.4882 99.6131 12.4882C98.8303 12.4882 98.2493 12.2545 97.8628 11.7921C97.4396 11.3591 97.2194 10.6995 97.1876 9.80051H104.954C104.984 9.74388 105 9.64242 105 9.4973V9.02065C105 7.11641 104.522 5.62747 103.563 4.55973ZM97.1876 7.85262C97.2182 7.10225 97.4396 6.5265 97.8628 6.11946C98.2811 5.71714 98.8193 5.51421 99.4749 5.51421C100.194 5.51421 100.746 5.71714 101.138 6.11946C101.498 6.49582 101.705 7.07276 101.766 7.85262H97.1888H97.1876Z"
        fill="currentColor"
      />
      <path
        d="M109 3.99406C109 4.55743 108.567 5 108.003 5C107.439 5 107 4.55743 107 3.99406C107 3.43069 107.445 3 108.003 3C108.561 3 109 3.44257 109 3.99406ZM107.25 3.99406C107.25 4.43663 107.571 4.78812 108.01 4.78812C108.449 4.78812 108.751 4.43663 108.751 4C108.751 3.56337 108.436 3.2 108.004 3.2C107.572 3.2 107.25 3.55743 107.25 3.99406ZM107.849 4.51485H107.623V3.52079C107.712 3.50297 107.836 3.4901 107.997 3.4901C108.181 3.4901 108.265 3.52079 108.335 3.56337C108.389 3.60594 108.43 3.68416 108.43 3.78119C108.43 3.8901 108.347 3.97525 108.229 4.01188V4.02376C108.324 4.0604 108.377 4.13267 108.407 4.26634C108.437 4.41782 108.454 4.47822 108.479 4.51485H108.235C108.205 4.47822 108.188 4.38713 108.158 4.27228C108.141 4.16337 108.08 4.11485 107.956 4.11485H107.85V4.51485H107.849ZM107.856 3.95149H107.962C108.087 3.95149 108.188 3.90891 108.188 3.80594C108.188 3.71485 108.123 3.65446 107.981 3.65446C107.922 3.65446 107.88 3.6604 107.856 3.66634V3.95149Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7_27">
        <rect width="109" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default LogoIcon
