import Link from 'next/link'

type Props = {
  label: string
  type?: HTMLButtonElement['type']
  variant?: 'outlined' | 'text'
  fluid?: boolean
  invertTextColor?: boolean
  underlineByDefault?: boolean
  hoverColorChange?: boolean
  href?: string
  onClick?: () => void
}
const Button = ({
  label,
  type = 'button',
  variant = 'outlined',
  fluid = true,
  invertTextColor = false,
  underlineByDefault = true,
  hoverColorChange = true,
  href,
  onClick
}: Props) => {
  let variantClasses: string
  let hoverClasses: string
  let activeClasses: string
  let focusClasses: string

  switch (variant) {
    case 'outlined':
      variantClasses = `ring-red ring-1 -ring-offset-1 rounded-full px-6 py-2`
      hoverClasses = 'hover:ring-0 hover:bg-red hover:text-white'
      activeClasses = 'active:ring-0 active:bg-red active:text-white'
      focusClasses = 'focus:ring-0 focus:bg-red focus:text-white'
      break
    case 'text':
      variantClasses = `relative py-2 ${hoverColorChange ? 'hover:text-red' : ''}`
      hoverClasses =
        (underlineByDefault
          ? `before:border-b-[1px] before:border-b-black ${hoverColorChange ? 'before:hover:border-b-red' : ''} ` +
            `before:absolute before:bottom-0 before:left-0 before:right-0 ` +
            `before:transition-transform before:duration-[750ms] before:ease-out ` +
            `before:scale-x-100 before:hover:scale-x-0 ` +
            `before:hover:origin-right before:origin-left before:delay-[250ms] before:hover:delay-0 `
          : '') +
        `after:border-b-[1px] after:border-b-black ${hoverColorChange ? 'after:hover:border-b-red' : ''} ` +
        `after:absolute after:bottom-0 after:left-0 after:right-0 ` +
        `after:transition-transform after:duration-[750ms] after:ease-out ` +
        `after:scale-x-0 after:hover:scale-x-100 ` +
        `after:hover:origin-left after:origin-right ${underlineByDefault ? 'after:hover:delay-[250ms]' : ''}`
      activeClasses = ''
      focusClasses = ''
      break
  }

  const allClasses = `text-body-lg whitespace-nowrap hover:no-underline box-border ${invertTextColor ? 'text-white' : 'text-black'} ${variantClasses} ${hoverClasses} ${activeClasses} ${focusClasses} ${!fluid ? 'w-fit' : ''}`

  if (!!href)
    return (
      <Link href={href} className={allClasses}>
        {label}
      </Link>
    )
  return (
    <button type={type} onClick={onClick} className={allClasses}>
      {label}
    </button>
  )
}

export default Button
