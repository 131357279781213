import Link from 'next/link'
import Button from './Button'

type Props = {
  isMenuOpen: boolean
  closeMenu: () => void
  navItems: {label: string; href: string}[]
}

const Menu = ({isMenuOpen = false, closeMenu, navItems}: Props) => {
  return (
    <div
      className={`transition-translate absolute left-0 top-0 overflow-hidden bg-white duration-300 ${isMenuOpen ? 'translate-y-0' : '-translate-y-[100vh] delay-200'} flex h-screen w-full flex-col items-center justify-center gap-y-16 lg:hidden`}
    >
      <nav className="flex flex-col items-center gap-y-6 text-headline-lg">
        {navItems.map(({label, href}, i) => (
          <Link
            key={href}
            href={href}
            aria-label={`Navigation link - ${label}`}
            onClick={closeMenu}
            className={`transition-transform duration-300 ${isMenuOpen ? 'translate-x-0 delay-200' : i % 2 ? 'translate-x-[100vw]' : '-translate-x-[100vw] ease-in'}`}
          >
            {label}
          </Link>
        ))}
      </nav>
      <div
        className={`transition-transform duration-300 ${isMenuOpen ? 'translate-y-0 delay-300' : 'translate-y-[100vw] ease-in'}`}
        onClick={closeMenu}
      >
        <Button label="Contact us" href="/contact" variant="text" />
      </div>
    </div>
  )
}

export default Menu
