import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/animations/FadeUpOnVisible.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/GoogleTagManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/components/ManageCookies.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/Azeret-Regular.woff2\",\"preload\":true,\"weight\":\"400\",\"variable\":\"--font-azeret\",\"display\":\"swap\"}],\"variableName\":\"azeretFont\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/Azeret-Medium.woff2\",\"preload\":true,\"weight\":\"500\",\"variable\":\"--font-azeret-medium\",\"display\":\"swap\"}],\"variableName\":\"azeretMediumFont\"}");
